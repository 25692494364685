@mixin extraLargeScreen(){
    @media (width > 1200px){
        @content;
          
    }
}

@mixin desktop(){
    @media (width <= 1200px){
        @content;
          
    }
}


@mixin laptop(){
    @media (width <= 1024px){
        @content;
          
    }
}

@mixin tablet(){
    @media (width <= 768px){
        @content;
          
    }
}


@mixin mobile(){
    @media (width <= 480px){
        @content;
        
        
    }
}










