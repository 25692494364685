@import"./mixins";

@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: Centra;
    src: url(./assets/font/CentraNo2-Book.ttf);
    font-weight: 400;
}

:root{
  
  --bg-light: #ffffff;
  --text-light:black;
  --text-light1:rgb(255, 255, 255);
  --border-light:black;
  --btn-light: #fff;
  --btn-light1:black;
  --background-light: url(./assets/img/day.jpg);
  --logo-light: url(./assets/img/logoLight.png);
  --light-mode: url(./assets/img/moon.svg);
  
  


//dark-them

   
   --bg-dark: #000000;
   --text-dark:rgb(255, 255, 255);
   --text-dark1:rgb(0, 0, 0);
   --border-dark:rgb(255, 255, 255);
   --btn-dark:black;
   --btn-dark1:white;
   --background-dark: url(./assets/img/night.jpg);
   --logo-dark: url(./assets/img/logoDark.png);
   --dark-mode:url(./assets/img/sun.svg);

  
}




*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body{
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    color: var(--color-light) ;
    font-family: 'Centra', sans-serif ;
    
}

h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
    line-height: normal;

}

p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  button {
    border: 0;
    background-color: transparent;
  }
  
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
  
  @media (min-width:1700px) {
      main .container {
          max-width: 100%;
          padding: 0 150px;
      }
  }
  
  p.success {
      color: green;
  }
  
  p.danger {
      color: red;
  }


  .wrapper{
    background: var(--bg-light);
    color: var(--text-light);
   overflow-x: hidden !important;


  }


 

  

  .checkbox .dark-mode-checkbox:not(:checked) + .menu-icon .mode-icon {
    background-color: #c71515;
  }
  
  .dark-mode-checkbox:checked ~ .wrapper{
    
    --bg-light:var(--bg-dark);
    --text-light:var(--text-dark);
    --text-light1:var(--text-dark1);
    --border-light:var(--border-dark);
    --btn-light:var(--btn-dark);
    --btn-light1:var(--btn-dark1);
    --background-light:var(--background-dark);
    --logo-light:var(--logo-dark);
    --light-mode: var(--dark-mode);

    
    

    
  }

  .dark-mode-checkbox{
    
    z-index: 999999 ; 
    color: rgb(8, 6, 6);
    position: sticky;
    left: 88rem;
    top: 3rem;
    height: 2rem;
    width: 2rem;
    opacity: 0;
    
  }

  
    label {
      height: 3rem;
      width: 3rem;
      position: sticky;
      left: 88rem;
      top: 3rem;
      z-index: 9999;
  }

  label i{
    font-size: 2rem;
    color: #0967a7;

  }

  @include extraLargeScreen(){

    .dark-mode-checkbox{
    
      height: 2rem;
      width: 2rem;
      left: 96%;
      top: 20%;
    
      
    }
  
    
      label {
        height: 2rem;
        width: 2rem;
        left: 96%;
        top: 20%;
        
    }
  }


  
  @include desktop(){

    .dark-mode-checkbox{
    
      height: 2rem;
      width: 2rem;
      left: 96%;
      top: 23.5%;
    
      
    }
  
    
      label {
        height: 2rem;
        width: 2rem;
        left: 96%;
        top: 23.5%;
        
    }
  }
  
  @include laptop(){

    .dark-mode-checkbox{
    
      height: 2rem;
      width: 2rem;
      left: 95%;
      top: 25%;
    
      
    }
  
    
      label {
        height: 2rem;
        width: 2rem;
        left: 95%;
        top: 25%;
        
    }
  }

  @include tablet(){

    .dark-mode-checkbox{
    
      height: 2rem;
      width: 2rem;
      left: 93%;
        top: 23%;
    
      
    }
  
    
      label {
        height: 2rem;
        width: 2rem;
        left: 93%;
        top: 23%;
        
    }
  }

  @include mobile(){

    .dark-mode-checkbox{
    
      height: 2rem;
      width: 2rem;
      left: 90%;
        top: 23%;
    
      
    }
  
    
      label {
        height: 2rem;
        width: 2rem;
        left: 90%;
        top: 23%;
        
    }
  }







  /************ Navbar Css ************/
  nav.navbar {
    background: var(--bg-light);
    border-bottom: var(--border-light) solid 1px;
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    transition:  0.32s ease-in-out;
  }
  nav.navbar.scrolled {
    padding: 0px 0;
    background-color: var(--bg-light);
  }

  .logo{
    height: 6.5rem;
    width: 6.5rem;
    background-image: var(--logo-light);
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  nav.navbar a.navbar-brand {
      width: 9%;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: var(--text-light);
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
  }
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
      opacity: 1;
  }

  span.navbar-text {
      display: flex;
      align-items: center;
  }
  .social-icon {
      display: inline-block;
      margin-left: 14px;
  }
  .social-icon a {
      width: 42px;
      height: 42px;
      background: var(--btn-light);
      display: inline-flex;
      border-radius: 50%;
      margin-right: 6px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid var(--border-light) ;
      text-decoration: none;
  }

  .social-icon a:hover i{
    color: var(--text-light1);

  }

  .social-icon a i{
    color: var(--text-light);
    z-index: 2;
  }
 
  .social-icon a::before {
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      background-color: var(--btn-light1);
      color: var(--text-light1);
      border-radius: 50%;
      transform: scale(0);
      transition: 0.3s ease-in-out;
      z-index: 1
  }
  .social-icon a:hover::before {
      transform: scale(1);
      
  }

  .social-icon a i:hover {
    color: var(--text-light1);
  }

  

  
  .navbar-text button {
    font-weight: 700;
  color: var(--bg-light);
  border: var(--border-light) solid;
  background-color: var(--btn-light1);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
  }
  .navbar-text button span {
    z-index: 1;
    position: relative;
  }
  .navbar-text button::before {
    content: "";
    content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--btn-light);
  left: 0;
  top: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  }

 
  .navbar-text button:hover  {
    color: var(--text-light) ;
    
   
  }

  .navbar-text button:hover::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
  }
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
  }
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    position: relative;
    border-bottom: 2px solid var(--border-dark);
    transition: all 300ms linear;
    top: -2px;
  }
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid var(--border-dark);
  }
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: var(--text-light);
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: var(--bg-light);
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: var(--bg-light);
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  }

  hr{
    color: var(--color-light);
    width: 1px;
  }

  @include mobile(){
    .tagline{
      text-align: center;
    }
  
  }

  /************ Hero Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: var(--background-light);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: #03314f;
  border: 1px solid var(--border-light);
  color: var(--text-dark);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}


.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  min-height: 6rem;
}

h1 {
  @include mobile(){
    // font-size: 2.5rem !important;
  }
  .design{
  color:#1a6da4;
  
  @include mobile(){
    font-size: 3rem !important;
  }
 
}
}

.cod{
  @include mobile(){
    font-size: 2.5rem !important;
    
  }

}




.btn button {
  font-weight: 700;
  color: var(--bg-light);
  border: var(--border-light) solid;
  background-color: var(--btn-light1);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.btn button span {
  z-index: 1;
  position: relative;
}
.btn button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--btn-light);
  left: 0;
  top: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.btn button:hover {
  color: var(--text-light) ;
 
  
}
.btn button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

/************ Skills ************/

.skill {
  padding: 80 0 50px 0;
  position: relative;
  background: var(--bg-light);
}
.skill-bx {
  border-radius: 64px;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.skill p {
    color: var(--text-light);
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
    text-align: center;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
  
  
}

.icon{
  width: 80%;
}

.spacer{
  margin-left: 8px;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: var(--bg-light);
}
.project h2 {
  color: var(--text-light);
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: var(--text-light);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 80%;
}
.project .nav.nav-pills {
  width: 75%;
  margin: 0 auto;
  border-radius: 50px;
  background: linear-gradient(245deg, rgb(0, 103, 154) 54.25%, rgb(0, 173, 239) 96.94%);
  overflow: hidden;
}


.project .nav.nav-pills .nav-item {
  width: 25%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 2px;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  overflow: hidden;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: #03314f;       
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.nav-link#projects-tabs-tab-fourth {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
  
}

/****** Project Card ***********/
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  
  
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(245deg, rgb(29, 143, 200) 54.25%, rgb(126, 205, 237) 96.94%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
  
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}


.proj-imgbx .proj-btn{
  position: absolute;
  top: 65%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  background: transparent;
  width: 100%;
}

.proj-imgbx:hover .proj-btn {
  top: 92.8%;
  opacity: 1;
}

.proj-imgbx .proj-btn button {
  font-weight: 700;
  width: 12.2rem  ;
  color: var(--color-light);
  border: 1px solid var(--border-light);
  padding: 18px 34px;
  font-size: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
.proj-btn button span {
  z-index: 1;
}
.proj-btn button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--btn-light);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.proj-btn button:hover {
  color: var(--text-light);
  
  
}
.proj-btn button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

@include mobile(){

  .proj-imgbx .proj-btn button {
    width: 10rem  ;
  }

}

  @include tablet(){

    .proj-txtx h4 {
      font-size: 20px;
      letter-spacing: 0.8px;
      line-height: 1em;
    }
    .proj-txtx span {
      display: none;
    }

    .proj-imgbx .proj-btn{
      
      display: flex;
      justify-content: space-between;
    }

    .proj-imgbx .proj-btn button {
      font-weight: 700;
      width: 6.9rem  ;
      padding: 10px 20px;
      font-size: 10px;
    
    }
  }

  @include laptop(){

    .proj-imgbx .proj-btn{
      
      display: flex;
      justify-content: space-between;
    }

    .proj-imgbx .proj-btn button {
      font-weight: 700;
      width: 6.9rem  ;
      padding: 10px 20px;
      font-size: 10px;
    
    }
  }

  @include desktop(){

    .proj-imgbx .proj-btn{
      
      display: flex;
      justify-content: space-between;
    }

    .proj-imgbx .proj-btn button {
      font-weight: 700;
      width: 6.9rem  ;
      padding: 10px 20px;
      font-size: 10px;
    
    }
  }




/******** About Me ********/

.about{
  background: var(--bg-light);
  padding: 80px 0;
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
}
.about h2{
  font-size: 45px;
	font-weight: 700;
  margin-bottom: 3rem;
}

.about h2, h5{
  text-align: center;
  color: var(--text-light);
}

.about-me {
  
  margin-top: auto;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  display: grid;
  place-items: center;

}

.aboutMeImg{
  border-radius: 2rem;
  overflow: hidden;
}



.about-cards{
  margin-top: 2rem;
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 gap: 1.5rem;
}

.about-card{
  width:10rem;
  background: #055387;
  border: 1px solid transparent; 
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about-card:hover{
  background: transparent;
  border-color: var(--border-light);
  color: var(--text-light);
  cursor: default;
}

.about-card h5{
  color: var(--text-light1);
  font-size: 0.95rem;
}

.about-card i {
  color: var(--text-light1);
}

.about-card:hover i {
  color: var(--text-light);
}

.about-card:hover h5{
  color: var(--color-light);
}

.about-icon{
  color: var(--color-dark);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}


.about-card small{
  font-size: 0.7rem;
  color: var(--text-light1);
}

.about-card:hover small{
  font-size: 0.7rem;
  color: var(--text-light);
}

.about-content p{
  margin:  2rem 0 2.6rem;
  color: var(--text-light);
  text-align-last: center;
}

.about-content button {
  font-weight: 700;
  color: var(--bg-light);
  border: 1px solid var(--border-light);
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
    position: relative;
    background-color: var(--btn-light1);
    transition: 0.3s ease-in-out;
}
.about-content button span {
  z-index: 1;
  position: relative;
}
.about-content button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--btn-light);
  left: 0;
  top: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.about-content button:hover {
  color: var(--text-light);
  
}
.about-content button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

@include mobile(){

  .about-content{
    margin-top: 6.5rem;
  }

  .about-me{
    margin-top: 5rem;
  }

}

@include tablet(){

  .about-me{
    margin-top: 5rem;
  }

  .about-content{
    margin-top: 6.5rem;
  }

}

@include laptop(){

  .about-cards{
    margin-top: 8rem;
  }

  .about-card{
    width:8rem;
    
  }

}


/*********** Contact ************/

.contact {
  background: var(--bg-light);
  padding: 60px 0 100px 0;
  
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: transparent;
  border: 1px solid var(--btn-light1);
  border-radius: 20px;
  color: var(--text-light);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: var(--btn-light1);
  color: var(--text-light1);
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-light);
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: var(--text-light1);
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: var(--bg-light);
  border: 1px solid var(--border-light);
  background-color: var(--btn-light1);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: var(--text-light);
}
.contact form button::before {
  content: "";
  background-color: var(--btn-light);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.email{
  background:#03314f;
  width: 20rem;
  padding: 0.5rem 1.5rem;
  border-radius: 100px;
  gap: 1rem;
  cursor: pointer;
}

.email a{
      
      color: white !important;
      text-decoration: none;

  }

  .email .send{
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #12B9AF;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-left: 1rem;
  }

  .email-section{
    margin-top:-5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .email-section h3{
    font-family: Arial, Helvetica, sans-serif;
  }

  @include mobile(){

    .email-section{
      margin-top: 4rem;
    }
  
  }
  
  @include tablet(){
  
  
    .email-section{
      margin-top: 3rem;
    }
  
  }
  
  @include laptop(){
  
    .email-section{
      margin-top: 3rem;
    }
  
  }

  @include desktop(){
  
    .email-section{
      margin-top: 3rem;
    }
  
  }




/***** Footer *******/
.footer{
  background: var(--bg-light);
  color: var(--color-light);
  border-top: var(--border-light) solid 1px;
  
}

.footer-container{
      max-width: 1440px;
      flex-direction: column;
      margin: 0 auto;
  }

.footer-top{
          justify-content: space-evenly;
          align-items: flex-start;
          padding: 3rem 0 0 0;
          width: 80%;
          margin: 0 auto;
          text-align: center;
}

.footer-logo{
  height: 8rem;
  width: 8rem;
  background-image: var(--logo-light);
  background-size: cover;
  background-repeat: no-repeat;
  
}




.footer-bottom{
          justify-content: center;
          padding-bottom: 2rem;
      }



/***** Mobile Nav *******/
// .mobileNav{
  
//   position: sticky;
//   width: 0;
//   top: 75%;
//   left: 60%;
//   z-index: 999999;
//   background: grey;
  

// }



// .mobilenav-icon {
//   display: inline-block;
//   background: grey;
//   padding: 0.5rem;
//   border-radius: 50px;
//   transform: rotate(90deg);
//   display: flex;
//   flex-direction: row;
//   width: 15rem;
  
  
  
// }
// .mobilenav-icon a {
//   width: 42px;
//   height: 42px;
//   background: var(--btn-light);
//   display: inline-flex;
//   flex-direction: column;
//   border-radius: 50%;
//   margin-right: 6px;
//   align-items: center;
//   justify-content: center;
//   line-height: 1;
//   border: 1px solid var(--border-light) ;
//   text-decoration: none;
//   transform: rotate(270deg);
// }

// .mobilenav-icon a:hover i{
// color: var(--text-light1);

// }

// .mobilenav-icon a i{
// color: var(--text-light);
// z-index: 2;
// }

// .mobilenav-icon a::before {
//   content: "";
//   width: 42px;
//   height: 42px;
//   position: absolute;
//   background-color: var(--btn-light1);
//   color: var(--text-light1);
//   border-radius: 50%;
//   transform: scale(0);
//   transition: 0.3s ease-in-out;
//   z-index: 1
// }
// .mobilenav-icon a:hover::before {
//   transform: scale(1);
  
// }

// .mobilenav-icon a i:hover {
// color: var(--text-light1);
// }

// @include mobile(){
  
//   .mobileNav{
//     display: block !important;
//   }

// }

//   @include tablet(){
//     .mobileNav{
//       display: block !important;
//     }
    
//   }

//   @include laptop(){
//     .mobileNav{
//       display: none;
//     }
    
//   }

//   @include desktop(){

//     .mobileNav{
//       display: none;
//     }

    
//   }

//   @include extraLargeScreen(){

//     .mobileNav{
//       display: none;
//     }


//   }





 










